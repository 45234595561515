/* eslint-disable quotes */
export const appConfig =
{
  "apiKey": "AIzaSyDGBkL7oSP1zkBCx0bFGHqUJV3V9KT6oKU",
  "appId": "1:490141766556:web:830e430995eb8502d76e59",
  "authDomain": "schooldog-i-bacon-ga.firebaseapp.com",
  "measurementId": "G-YWY2Z7W6DN",
  "messagingSenderId": "490141766556",
  "project": "schooldog-i-bacon-ga",
  "projectId": "schooldog-i-bacon-ga",
  "showGaBranding": true,
  "storageBucket": "schooldog-i-bacon-ga.appspot.com"
}
